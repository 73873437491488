import React from 'react'

const LegalPolicy = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> Skillwinz Legal Policy </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"> Legal Policy </li>
                        </ol>
                    </nav>
                </div>
            </div>
            
            
            <main> 
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>Legal  </h2></div>
                </section>
                <section class="">
                    <div class=" container">
                        <div  class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">  

                            Most definitely–Yes. Playing rummy online at Skillwinz is legal In India provided you are not playing from Assam, Telangana, Andhra Pradesh, Sikkim, Nagaland, Meghalaya, & Odisha. Rummy has been declared as a game of skill process as stated by the Supreme Court of India in 1968. Rummy has been held to be a “game of mere skill” in a plethora of judgments by the Supreme Court and high courts of India. In 1996, Supreme Court of India held that: A game of skill, on the other hand - although the element of chance necessarily cannot be entirely eliminated, it is one in which success depends principally upon the superior knowledge, training, attention, experience and adroitness of the player. Golf, chess and even Rummy are considered to be games of skill. The Courts have reasoned that there are few games, if any, which consist purely of chance or skill, and as such a game of chance is one in which the element of chance predominates over the element of skill, and a game of skill is one in which the element of skill predominates over the element of chance. It is the dominant element --"skill" or "chance" -- which determines the character of the game.
                            
                            </div>
                        </div>
                    </div>
                </section>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>Does playing rummy online categorise as 'Gambling'?  </h2>
                        <p>Short answer – No. Read on for the long answer.</p>
                        </div>
                </section>
                <section class="">
                    <div class=" container">
                        <div  class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">   The Supreme Court in two landmark cases in 1957 has held that competitions which involve substantial skill are not gambling activities; Such competitions are business activities, the protection of which is guaranteed under Article 19(1) (g) of the Constitution of India. It was clearly laid down by the Supreme Court that a “game of skill” is protected under Article 19(1)(g) of the Constitution of India and, therefore, profit or gain from any activity based on skill cannot be treated as gaming or gambling.</div>
                        </div>
                    </div> 
                </section>
                <section class="">
                    <div class=" container">
                        <div  class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">However, in Assam, Telangana, Andhra Pradesh, Sikkim, Nagaland, Meghalaya, & Odisha players are not allowed to play for cash. Skillwinz does not allow players from these states to play for real money. But, they can still play our practice games for free.</div>
                        </div>
                    </div> 
                    </section>
                <section class="">
                    <div class=" container">
                        <div  class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">or any queries you can write to support@skillwinz.com . You can Refer to Our Terms of service for more details.</div>
                        </div>
                    </div>
                </section>
                 
            </main>
            <br/>
            <br/>


            {/* <!-- Copyright Area Start --> */}
            <div className="copyright-area  ptb-70 pt-0 pb-4">
                <div className="container">
                    {/* <!-- Contact address left --> */}
                    <div className="conct-border row align-items-center">
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" /> </a>
                        </div>
                        <div className="col-md-7">
                            <div className="single-address">
                                <a href="/Legal Policy"  >Skillwinz Legal Policy</a>
                                <a href="/termsconditions">Terms & Conditions</a>
                                <a href="/privacypolicy">Privacy Policy</a>
                                <a href="/legality"> Legality </a>
                                <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                <a href="/anti-money-laundering-policy">Anti-Money Laundering  </a>
                                <a href="/refund-policy"> Refund Policy  </a>
                                <a href="/contact"> Contact us    </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Contact address left --> */}
                    {/* <!-- Copyright right --> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-area text-center">
                                {/* <!-- Copyright social --> */}
                                {/* <!-- <div className="contact-social text-center ptb-50 pb-35">
                                    <ul>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-pinterest"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-vimeo"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-google-plus"></i></a>
                                        </li>
                                    </ul>
                                </div> --> */}
                                {/* <!-- Copyright social --> */}
                                <div className="copyright-text">
                                    <p>Copyright &copy;  <a href="#"> Skill Winz </a> All Rights Reserved. <br/>Skillwinz is a part of  Fantasy Score 11 Pvt. Ltd.</p>


                                    <p>
                                        <b>DISCLAIMER</b><br/>
                                        This game may be habit-forming or financially risky. Play responsibly. Skillwinz is the biggest social gaming app in India by number of games, languages and exciting formats on the platform. Skillwinz is only available for people who are above 18 years of age. Skillwinz is available only in those Indian states where skill gaming is allowed by regulations.Fantasy Score 11 Pvt. Ltd. is the sole owner of and reserves the right to “Skillwinz” trademark, logos, assets, content, information, etc. used in the website except the third party content. Fantasy Score 11 Pvt. Ltd. does not acknowledge the accuracy or reliability of third party content.
                                    </p>
                                    
                                </div>
                                {/* <!-- Copyright text --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Copyright right --> */}
                </div>
            </div >
            {/* <!-- Copyright Area End --> */}
        </>
    )
}

export default LegalPolicy
