import React from 'react'

const AntiMoneyLaundering = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> Anti-Money Laundering Policy </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"> Anti-Money Laundering Policy </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="common_content_box">

            <div id="privacypolicy" class=" container">
                <p class="">Fantasy Score 11 Pvt. Ltd. (<b class="">Company, We, Us</b>) is committed to carrying on business in accordance with the highest ethical and business standards. This includes complying
                    with all applicable laws and regulations aimed at combating money laundering and related illegal activities.</p>
                <p class="">As an integral part of its business operations, the Company has instituted compliance measures commensurate with its Services, and to deter any potential illicit activity on its Platform. These measures may include, without limitation, onboarding
                    and ongoing customer screening, and transaction-based controls.</p>
                <p class="">This Anti Money Laundering Policy (<b class="">AML Policy</b>) describes the Company’s measures, to detect and deter illicit activity on its Platform. It must be read in conjunction with our Terms of Use (<a href="https://skillwinz.com/terms-conditions"
                        class="">https://skillwinz.com/termsconditions</a>) (<b class="">Terms</b>) and Privacy Policy (<a href="/privacypolicy" class="">https://skillwinz.com/privacy-policy </a>)
                    (<b class="">Privacy Policy</b>) and is binding on all users.</p>
                <p class="">Capitalized and undefined terms used in this AML Policy have the same meaning as in our Terms unless the context indicates otherwise.</p>
                <p class=""><b class="">User Identification Protocol</b></p>
                <ol class="">
                    <li class="">
                        <p class=" listMargin">The Company takes reasonable measures to establish the identity of any person to whom it provides Services.</p>
                    </li>
                    <li class="">
                        <p class=" listMargin">In order to do so, the Company collects certain minimum player identification details from each User, who registers an account with Us, in accordance with our Terms and the Privacy Policy.</p>
                    </li>
                    <li class="">
                        <p class=" listMargin">Registered Users who wish to withdraw funds from their account, may be required to first have their accounts officially confirmed by the Company. For this, the Company may compulsorily require Users to provide valid photo identification, and
                            address proof documents for proof of identity and address. Failure to provide the Company with a valid bank account or valid identification documents (to its satisfaction) may result in the forfeiture of amounts sought to be withdrawn
                            by a User.</p>
                    </li>
                    <li class="">
                        <p class=" listMargin">Withdrawals may, therefore, be permitted subject strictly to the submission of a User’s PAN, Aadhaar verification, geolocation verification, and the withdrawal bank details, as per verified documents.</p>
                    </li>
                    <li class="">
                        <p class=" listMargin">The Company may also seek further documents from the Users to conduct Know Your Customer (KYC) verification, in accordance with applicable laws. Further, the Company reserves the right to request additional personal information from Users,
                            in the interests of security, enhanced customer service, and compliance requirements. This includes, without limitation, Users’ proof of address, government-authorised identification cards, and other information/documents as may be required
                            by the Company.</p>
                    </li>
                </ol>
                <p class=""><b class="">User Obligations</b></p>
                <ol start="6" class="">
                    <li class="">
                        <p class=" listMargin">Users accept that they must comply with this AML Policy. Users specifically confirm that they neither do they intend to, and nor are they using the Platform and our Services to commit any listed or criminal offences.</p>
                    </li>
                    <li class="">
                        <p class=" listMargin">Users must ensure that they submit accurate and up-to-date personal information, that belongs to them.</p>
                    </li>
                </ol>
                <p class=""><b class="">Transaction Monitoring</b></p>
                <ol start="8" class="">
                    <li class="">
                        <p class=" listMargin">We monitor activity on the Platform, through manual and automated means, on an ongoing basis. Such monitoring may be performed using rule-based systems developed in-house and by third parties, to review User history and activity patterns,
                            to check for unusual or suspicious behaviour, and implement additional controls or limits on the Platform. Users may make payments for Services only through integrated payment gateways, through electronic means. We neither accept payments
                            from Users, nor allow withdrawals by Users in the form of cash.</p>
                    </li>
                    <li class="">
                        <p class=" listMargin">If we detect unusual or suspicious User behaviour, we may take actions we deem appropriate, including without limitation:</p>
                        <ol type="i" class="">
                            <li class="">ask Users to submit more information and/or documents;</li>
                            <li class="">mandate additional KYC verification;</li>
                            <li class="">restrict, suspend, or terminate a User’s account;</li>
                            <li class="">disallow withdrawal of funds by a User; and</li>
                            <li class="">forfeit amounts sought to be withdrawn by a User.</li>
                        </ol>
                    </li><br/>
                    <li class="">
                        <p class=" listMargin">We monitor Users’ relationships in accordance with the best industry practices, and in alignment with international recommendations, guidelines, and commitments. The extent of monitoring may depend on various factors including upon each User’s
                            risk profile, volume, value of transaction, time of transaction, and frequency of transactions with similar players, table etc.</p>
                    </li>
                    <li class="">
                        <p class=" listMargin">Any User account which may be involved in suspicious transactions potentially related to money laundering, or other offence, may be reported to the appropriate law enforcement agencies. For the purposes of this clause, suspicious transactions
                            may mean and/or include:</p>
                        <ol type="i" class="">
                            <li class="">unusually complex transactions;</li>
                            <li class="">transactions which do not appear to be undertaken in good faith;</li>
                            <li class="">transactions which do not appear to have any economic basis;</li>
                            <li class="">transactions which appear to involve proceeds of any offence listed in the Schedule to the Prevention of Money Laundering Act, 2002.</li>
                        </ol>
                    </li><br/>
                    <li class="">
                        <p class=" listMargin">We maintain records of transactions occurring on our Platform for such timelines that are required under the applicable laws.</p>
                    </li>
                    <li class="">
                        <p class=" listMargin">For compliance with this policy, we may reach out to you from time to time.</p>
                    </li>
                </ol>
                <p class=""><b class="">Changes to AML Policy</b></p>
                <ol start="14" class="">
                    <li class="">
                        <p class=" listMargin">The Company may revise this AML Policy from time to time. Such changes may be made without prior notice. We will, in any event, periodically inform our users of this AML Policy, and any change in this Policy, as required by law.</p>
                    </li>
                    <li class="">
                        <p class=" listMargin">Updates to this AML Policy, if any, will be made available on our official website, <a href="https://skillwinz.com/" class=""> https://skillwinz.com/ </a>.</p>
                    </li>
                </ol>
            </div>

            </section>
            {/* <!-- Copyright Area Start --> */}
            <div className="copyright-area  ptb-70 pt-0 pb-4">
                <div className="container">
                    {/* <!-- Contact address left --> */}
                    <div className="conct-border row align-items-center">
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" /> </a>
                        </div>
                        <div className="col-md-7">
                            <div className="single-address">
                                <a href="/fairplay"  >Skillwinz Fairplay</a>
                                <a href="/termsconditions">Terms & Conditions</a>
                                <a href="/privacypolicy">Privacy Policy</a>
                                <a href="/legality"> Legality </a>
                                <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                <a href="/anti-money-laundering-policy">Anti-Money Laundering  </a>
                                <a href="/refund-policy"> Refund Policy  </a>
                                <a href="/contact"> Contact us    </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Contact address left --> */}
                    {/* <!-- Copyright right --> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-area text-center">
                                {/* <!-- Copyright social --> */}
                                {/* <!-- <div className="contact-social text-center ptb-50 pb-35">
                                    <ul>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-pinterest"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-vimeo"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-google-plus"></i></a>
                                        </li>
                                    </ul>
                                </div> --> */}
                                {/* <!-- Copyright social --> */}
                                <div className="copyright-text">
                                    <p>Copyright &copy;  <a href="#"> Skill Winz </a> All Rights Reserved.  by<br/>Skillwinz is a part of  Fantasy Score 11 Pvt. Ltd.</p>
                                
                                    <p>
                                        <b>DISCLAIMER</b><br/>
                                        This game may be habit-forming or financially risky. Play responsibly. Skillwinz is the biggest social gaming app in India by number of games, languages and exciting formats on the platform. Skillwinz is only available for people who are above 18 years of age. Skillwinz is available only in those Indian states where skill gaming is allowed by regulations.Fantasy Score 11 Pvt. Ltd. is the sole owner of and reserves the right to “Skillwinz” trademark, logos, assets, content, information, etc. used in the website except the third party content. Fantasy Score 11 Pvt. Ltd. does not acknowledge the accuracy or reliability of third party content.
                                    </p>
                                </div>
                                {/* <!-- Copyright text --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Copyright right --> */}
                </div>
            </div >
            {/* <!-- Copyright Area End --> */}
        </>
    )
}

export default AntiMoneyLaundering
