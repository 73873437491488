import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation, EffectCards } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";

const LandingPage = () => {
  console.log("Rajendra Jodha");
  return (
    <>
      <div className="hero-area slider-1 parallax" id="slider-area">
        <div className="slider">
          <div className="container">
            <div className="row align-items-center ">
              <div className=" col-lg-6 ">
                <div className="hero-text mr-ri-l">
                  <h1 className="text_gradient top_txt">
                    {" "}
                    <b>Welcome To Skill Winz</b>
                  </h1>
                  <h2>
                    {" "}
                    Play <span className="text_gradient"></span>{" "}
                    <span className="text_gradient">Rummy </span> <br /> Win
                    Money Unlimited{" "}
                  </h2>
                  <h5 className="text-white">
                    Get <b className="text_gradient">Rs.100</b> instant to play
                    games
                  </h5>
                  <a
                    // href="https://drive.google.com/uc?export=download&id=1MhtL_U-iz64Lm8Fhab6HkXIiHm1Y-ivU"
                    href="https://download.skillwinz.com/"
                    className="hero-btn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <i className="icofont icofont-download-alt me-2"></i>{" "}
                    Download App Now{" "}  
                  </a>
                </div>
              </div>
              <div className=" col-lg-6">
                <img src="img/main_img.png" className="img-fluid main_img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dn_row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="down_row ">
                <div className="down_data me-md-5 pe-md-5">
                  <img src="img/users.png" className="img-fluid" width="50" />
                  <h3>
                    1 million +<small>Trusted Users</small>
                  </h3>
                </div>
                <div className="down_data">
                  <img src="img/crown.png" className="img-fluid" width="50" />
                  <h3>
                    1 crore +<small> Daily Winnings </small>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="down_row  justify-content-end">
                <a
                  // href="https://drive.google.com/uc?export=download&id=1MhtL_U-iz64Lm8Fhab6HkXIiHm1Y-ivU"
                  href="https://download.skillwinz.com/"
                  className="me-3 me-md-4 "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img
                    src="img/play_1.png"
                    width="170"
                    className="img-fluid"
                  />{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="about" className="about-area pt-130">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="about-img">
                <img src="img/about_img.png" className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="about-content">
                <h2>
                  About The{" "}
                  <span className="text_gradient">Skill Winz Rummy</span>{" "}
                </h2>
                <p>
                  "Skill Winz," a unique spin on the classic Rummy game, adds a
                  thrilling twist to traditional gameplay. Players strategize to
                  outsmart opponents, combining skill and luck to conquer the
                  board. With a catchy name and exciting features, Skill Winz
                  brings a fresh and competitive edge to the timeless joy of
                  Rummy.
                </p>
                <a
                  className="hero-btn"
                  // href="https://drive.google.com/uc?export=download&id=1MhtL_U-iz64Lm8Fhab6HkXIiHm1Y-ivU"
                 href="https://download.skillwinz.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  Download Now{" "}
                </a>

                <div className="row play_bar g-3">
                  <div className="col-6  col-md-6">
                    <div className="play_card">
                      <h4 className="text_gradient">₹ 14,566</h4>
                      <p className="mb-0">Today Win Upto</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="features" className="service-area ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="section-heading w-100 pb-55">
                <h2>
                  {" "}
                  Great <span className="text_gradient">Features</span>{" "}
                </h2>
                <p>
                  {" "}
                  Skill Winz Rummy offers a thrilling twist, blending strategy
                  and luck for an engaging experience. Its unique features
                  redefine traditional gameplay.
                </p>
                <ul className="f-list">
                  <li>
                    <img src="img/arrow.svg" />{" "}
                    <span>
                      Strategic Gameplay: Skill Winz adds depth to Rummy.
                    </span>
                  </li>
                  <li>
                    <img src="img/arrow.svg" />
                    Unique Twist: A fresh take on the traditional board game.
                  </li>
                  <li>
                    <img src="img/arrow.svg" />
                    Competitive Edge: Blending skill and luck for exciting
                    matches.
                  </li>
                  <li>
                    <img src="img/arrow.svg" />
                    Revolutionary Experience: Skill Winz redefines the joy of
                    Rummy.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-6  mb-4">
                  <div className="single-service service1">
                    <div className="service-icon">
                      <img
                        src="img/gf_1.png"
                        width="100"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="service-content">
                      <h2>
                        {" "}
                        Instant <br /> Withdrawals{" "}
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-6  mb-4 mt-5">
                  <div className="single-service service2">
                    <div className="service-content">
                      <h2>
                        Strategic <br /> Gameplay
                      </h2>
                    </div>
                    <div className="service-icon">
                      <img
                        src="img/gf_2.png"
                        width="100"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6  mb-4 mt-minus-5">
                  <div className="single-service service3">
                    <div className="service-icon">
                      <img
                        src="img/gf_3.png"
                        width="100"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="service-content">
                      <h2>
                        100% Secure <br /> payments
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-6  mb-4">
                  <div className="single-service service4">
                    <div className="service-content">
                      <h2>
                        24/7 Customer <br /> support
                      </h2>
                    </div>
                    <div className="service-icon">
                      <img
                        src="img/gf_4.png"
                        width="100"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Screenshoot" className="Screenshoot-area ">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-md-12">
              <div className="section-heading pb-55 text-center ">
                <h2>
                  {" "}
                  <span className="text_gradient">Game </span> Screenshot
                </h2>
                <p>
                  {" "}
                  Experience excitement in every captivating game screenshot.{" "}
                </p>
              </div>
              <div className="screens_sec">
                <Swiper
                  className="preview_swiper "
                  spaceBetween={10}
                  loop={true}
                  autoplay={{ delay: 2000 }}
                  effect={"cards"}
                  pagination={{ dynamicBullets: true }}
                  modules={[Autoplay, Pagination, Navigation, EffectCards]}
                >
                  <SwiperSlide className="rounded-xl">
                    <a href="#/" className="main_poster">
                      <img alt="" src="img/screens/1.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide className="rounded-xl">
                    <a href="#/" className="main_poster">
                      <img alt="" src="img/screens/2.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide className="rounded-xl">
                    <a href="#/" className="main_poster">
                      <img alt="" src="img/screens/3.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide className="rounded-xl">
                    <a href="#/" className="main_poster">
                      <img alt="" src="img/screens/4.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide className="rounded-xl">
                    <a href="#/" className="main_poster">
                      <img alt="" src="img/screens/5.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide className="rounded-xl">
                    <a href="#/" className="main_poster">
                      <img alt="" src="img/screens/6.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide className="rounded-xl">
                    <a href="#/" className="main_poster">
                      <img alt="" src="img/screens/9.png" />
                    </a>
                  </SwiperSlide>
                  <SwiperSlide className="rounded-xl">
                    <a href="#/" className="main_poster">
                      <img alt="" src="img/screens/10.png" />
                    </a>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg_img">
        <section id="games" className="pricing-area pt-130 pb-100">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-heading pb-55 text-center">
                  <h2>Our Games</h2>
                  <p>
                    {" "}
                    Explore a multitude of captivating games for endless
                    entertainment and joyous gameplay variety. Discover, play,
                    and thrive!
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 my-3">
                <div className="play_card p-4">
                  <img src="img/og_1.svg" className="img-fluid" />
                  <div className="ordr-btn uppercase">
                    <h3 className=" mb-3">
                      {" "}
                      <b>Rummy Game</b>{" "}
                    </h3>

                    <a
                     // href="https://drive.google.com/uc?export=download&id=1MhtL_U-iz64Lm8Fhab6HkXIiHm1Y-ivU"
                     href = "https://download.skillwinz.com/"
                     className="btn_img updated_by_29_feb"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Play Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="feature-area pb-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-heading pb-55 text-center">
                  <h2>How to Download</h2>
                  <p>
                    Download Skill Winz effortlessly: Visit our website or app
                    store listings. Enjoy thrilling games and start your
                    immersive experience now!
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 mt-5">
                <div className="awesome-feature awesome-feature_step_1">
                  <div className="awesome-feature-details">
                    <img src="img/hd_1.png" className="img-fluid" />
                    <p>
                      Open{" "}
                      <strong className="text-underline">skillwinz.com</strong>{" "}
                      & click on “Download for Android” button
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-5">
                <div className="awesome-feature awesome-feature_step_2">
                  <div className="awesome-feature-details">
                    <img src="img/hd_2.png" className="img-fluid" />
                    <p>
                      Open Skill Winz & Wait for the apk to download & click on
                      “Open”
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mt-5">
                <div className="awesome-feature awesome-feature_step_3">
                  <div className="awesome-feature-details">
                    <img src="img/hd_3.png" className="img-fluid" />
                    <p>
                      Click “Allow for this Source” when prompted & then click
                      on Install
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="team" className="team-area ptb-130">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-heading pb-55 text-center">
                  <h2>
                    Our <span className="text_gradient">Game Winners</span>{" "}
                  </h2>
                  <p>
                    Celebrate the prowess of our victorious players, showcasing
                    skillful gameplay and extraordinary achievements. Join the
                    winners' circle!{" "}
                  </p>
                </div>
              </div>
            </div>
            <Swiper
              className="market_slider  pb-11"
              spaceBetween={10}
              loop={true}
              autoplay={{
                delay: 2000,
              }}
              pagination={{
                dynamicBullets: true,
              }}
              modules={[Autoplay, Pagination, Navigation]}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }}
            >
              <SwiperSlide>
                <div className="team-single">
                  <h3 className="mb-0 text_gradient">Won 1 Lakh</h3>
                  <p>
                    {" "}
                    " Hats off to our triumphant game user! Their skill and
                    strategy paid off, securing a fantastic victory of 1 lakh.
                    Bravo! "{" "}
                  </p>
                  <div className="profile">
                    <div className="profile-img">
                      <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                    </div>
                    <div className="name-user">
                      <strong>PixelMasterX </strong>
                      <span> Top Rummy player </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="team-single">
                  <h3 className="mb-0 text_gradient">Won 9 Lakh</h3>
                  <p>
                    {" "}
                    " Cheers to our game user's stellar achievement! They
                    clinched a phenomenal 9 lakh win, showcasing their gaming
                    prowess and strategic brilliance. "{" "}
                  </p>
                  <div className="profile">
                    <div className="profile-img">
                      <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                    </div>
                    <div className="name-user">
                      <strong>ThunderStriker </strong>
                      <span> Top Rummy player </span>
                      {/* <span> Top  Rummy player </span> */}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="team-single">
                  <h3 className="mb-0 text_gradient">Won 5 Lakh</h3>
                  <p>
                    {" "}
                    " Thrilling news! A game user triumphs with a spectacular 5
                    lakh win, showcasing skill and strategy. Congratulations on
                    this incredible achievement! "{" "}
                  </p>
                  <div className="profile">
                    <div className="profile-img">
                      <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                    </div>
                    <div className="name-user">
                      <strong>LunaQuester </strong>
                      <span> Top Rummy player </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="team-single">
                  <h3 className="mb-0 text_gradient">Won 2 Lakh</h3>
                  <p>
                    {" "}
                    " Incredible! A game user celebrates a massive victory,
                    securing a whopping 2 lakh. Congratulations on your
                    outstanding achievement and success! "{" "}
                  </p>
                  <div className="profile">
                    <div className="profile-img">
                      <img src="https://cdn3.iconfinder.com/data/icons/avatars-15/64/_Ninja-2-512.png" />
                    </div>
                    <div className="name-user">
                      <strong>BlazeGamer92 </strong>
                      <span> Top Rummy player </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </section>
      </div>
      <section id="faq" className="subcribe-area pb-115">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading pb-35 text-center">
                <h2>
                  {" "}
                  <span className="text_gradient">Skill Winz FAQs</span>
                </h2>
                <p>
                  {" "}
                  Find answers to common queries, troubleshooting tips, and
                  game-related inquiries. Explore our comprehensive FAQ section
                  for guidance.
                </p>
              </div>
            </div>
          </div>
          <div id="accordionFlushExample" className="accordion accordion-flush">
            <div className="row">
              <div className="col-lg-6">
                <div className="accordion-item">
                  <h5 className="accordion-header" id="feed_one">
                    <button
                      className="button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#feed_onea"
                      aria-expanded="false"
                      aria-controls="feed_onea"
                    >
                      <span> Is the game of rummy legal in India? </span>
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </h5>
                  <div
                    id="feed_onea"
                    className="accordion-collapse collapse"
                    aria-labelledby="feed_one"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="acc_body">
                      Yes, online rummy is legal in India. In 1968, the Supreme
                      Court of India passed a judgment that deemed rummy a game
                      of skill. However, a few states, Andhra Pradesh,
                      Telangana, Odisha, Meghalaya, Assam, Sikkim and Nagaland,
                      based on their legal discretion, have restricted skill
                      based games that involve stakes.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h5 className="accordion-header" id="two_one">
                    <button
                      className="button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#two_onea"
                      aria-expanded="false"
                      aria-controls="feed_onea"
                    >
                      <span> Is the Skillwinz app safe to use? </span>
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </h5>
                  <div
                    id="two_onea"
                    className="accordion-collapse collapse"
                    aria-labelledby="two_one"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="acc_body">
                      Yes, Skillwinz is completely safe and secure to use, and
                      follows strict fair game play policies. Skillwinz is an
                      ISO-certified gaming platform. All payment gateways used
                      by Skillwinz are PCI DSS (Payment Card Industry Data
                      Security Standards) compliant. They follow a universal set
                      of rules and regulations that are set by global entities
                      like Visa, Mastercard etc. Skillwinz has a “No-bot”
                      certification, which clearly states that we do not have
                      any systems that allow the usage of bots to control,
                      mimic, assist, aid or override real player actions.
                      Skillwinz’s card shuffling system has been RNG certified
                      by iTech Labs (Australia). RNG uses an algorithm to
                      generate random numbers which ensure that all players get
                      cards selected randomly from a well-shuffled deck and are
                      dealt with fairly by the system without any bias.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h5 className="accordion-header" id="three_one">
                    <button
                      className="button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#three_onea"
                      aria-expanded="false"
                      aria-controls="feed_onea"
                    >
                      <span>
                        {" "}
                        How do I download the Skillwinz app? Is it available on
                        Google Play Store?{" "}
                      </span>
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </h5>
                  <div
                    id="three_onea"
                    className="accordion-collapse collapse"
                    aria-labelledby="three_one"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="acc_body">
                      There are two ways to get the Skillwinz app on your
                      mobile: Download it from www.Skillwinz.com. Visit
                      www.Skillwinz.com and download the app by simply clicking
                      on the “Download Now” button. You will need to install the
                      downloaded apk. Register on the app with your mobile
                      number to start playing. Download it from App Store. Open
                      App Store on your Apple Device and search for “Skillwinz
                      app”. Download the Skillwinz app. Register with your
                      mobile number. Complete your KYC verification. You will
                      need your PAN card and an address proof (Aadhaar card,
                      Voter ID, etc.) to verify your KYC. It only takes 2
                      minutes. Once your KYC documents have been verified, you
                      can start playing cash games on the app.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h5 className="accordion-header" id="four_one">
                    <button
                      className="button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#four_onea"
                      aria-expanded="false"
                      aria-controls="feed_onea"
                    >
                      <span>
                        {" "}
                        Can I play rummy on the Skillwinz app without depositing
                        any money?{" "}
                      </span>
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </h5>
                  <div
                    id="four_onea"
                    className="accordion-collapse collapse"
                    aria-labelledby="four_one"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="acc_body">
                      Yes, our practice games are free. You can play as many
                      practice games as you want. Open the Skillwinz app and
                      click on “Practice”, located on the left bottom corner.
                      Select the practice game you want to play (Deals, Pool,
                      Points). Select the number of players you want to play
                      with (2 or 6) and start the game. You can also try out our
                      free tournaments. Open the Skillwinz app and click on
                      “Tournaments”, located on the left corner. Click on “Free
                      Tourney”. You will see all the free tournaments available
                      for you to play. Select the tournament you want to play
                      and register for it. Check the timings and wait for it to
                      start.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h5 className="accordion-header" id="five_one">
                    <button
                      className="button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#five_onea"
                      aria-expanded="false"
                      aria-controls="feed_onea"
                    >
                      <span>
                        {" "}
                        How can I add cash to the Skillwinz account? And is
                        there any maximum or minimum limit?
                      </span>
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </h5>
                  <div
                    id="five_onea"
                    className="accordion-collapse collapse"
                    aria-labelledby="five_one"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="acc_body">
                      To add cash to the Skillwinz account: Click on the “Add
                      Cash” button located at the top right corner of the app.
                      Enter the amount you want to add. Select a payment mode
                      through which you want to add cash. You can add cash
                      through UPI (GPay, PhonePe, Paytm), credit/debit cards,
                      Paytm wallet, and net banking. The minimum amount you can
                      add is ₹50. You can add up to ₹500 if your KYC documents
                      are not verified.. You can add up to ₹50,000 if your KYC
                      documents are verified.
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="accordion-item">
                  <h5 className="accordion-header" id="six_one">
                    <button
                      className="button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#six_onea"
                      aria-expanded="false"
                      aria-controls="six_onea"
                    >
                      <span>
                        Is my data (personal info/bank account details) safe
                        with Skillwinz?
                      </span>
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </h5>
                  <div
                    id="six_onea"
                    className="accordion-collapse collapse"
                    aria-labelledby="six_one"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="acc_body">
                      Yes, your data is completely safe with Skillwinz. At
                      Skillwinz, we take technical and organizational measures
                      to keep your personal information and bank account details
                      secure. All information transmission across systems is
                      done through secure channels to prevent the interception
                      of sensitive data. Our app is SSL (Secure Sockets Layer)
                      certified. It keeps all your data safe through encryption.
                      As per our corporate policy, we do not share your data
                      with any 3rd party.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h5 className="accordion-header" id="seven_one">
                    <button
                      className="button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#seven_onea"
                      aria-expanded="false"
                      aria-controls="seven_onea"
                    >
                      <span> Is my money safe on Skillwinz?</span>
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </h5>
                  <div
                    id="seven_onea"
                    className="accordion-collapse collapse"
                    aria-labelledby="seven_one"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="acc_body">
                      Yes, your money is completely safe with Skillwinz. The
                      amount you deposit in your Skillwinz account is maintained
                      in an escrow account with a regulated entity. An escrow
                      account is maintained by a 3rd party which ensures all
                      your payments are swift and secure. All our payment
                      gateways adhere to PCI DSS (Payment Card Industry Data
                      Security Standards). They follow a universal set of rules
                      and regulations that are set by global entities like Visa,
                      Mastercard etc. By verifying your KYC details, we apply
                      additional checks to keep your account safe.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h5 className="accordion-header" id="nine_one">
                    <button
                      className="button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#nine_onea"
                      aria-expanded="false"
                      aria-controls="nine_onea"
                    >
                      <span>
                        {" "}
                        How can I get in touch with the customer support team of
                        Skillwinz?{" "}
                      </span>
                      <i className="fa fa-angle-down"></i>
                    </button>
                  </h5>
                  <div
                    id="nine_onea"
                    className="accordion-collapse collapse"
                    aria-labelledby="nine_one"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="acc_body">
                      Skillwinz’s customer support team is ready to assist you
                      24*7. You can reach out to them by writing an email to
                      support@Skillwinz.com or call at +91 7878140068. They can
                      help you with add cash, withdrawal, game play, or any
                      other topics related to Skillwinz.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="copyright-area  ptb-70 pt-0 pb-4">
        <div className="container">
          <div className="conct-border row align-items-center">
            <div className="col-md-5">
              <a href="/">
                {" "}
                <img src="img/icon/logo.svg" />{" "}
              </a>
            </div>
            <div className="col-md-7">
              <div className="single-address">
                <a href="/fairplay">Skillwinz Fairplay</a>
                <a href="/termsconditions">Terms & Conditions</a>
                <a href="/privacypolicy">Privacy Policy</a>
                <a href="/legality"> Legality </a>
                <a href="/responsible-gaming-policy"> Responsible Gaming </a>
                <a href="/anti-money-laundering-policy">
                  Anti-Money Laundering{" "}
                </a>
                <a href="/refund-policy"> Refund Policy </a>
                <a href="/contact"> Contact us </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="copyright-area text-center">
                <div className="copyright-text">
                  <p>
                    Copyright &copy; <a href="/"> Skill Winz </a> All Rights
                    Reserved.{" "}
                    Skillwinz is a part of Fantasy Score 11 Pvt. Ltd.
                  </p>
                  <p>
                    <b>DISCLAIMER</b>
                    <br />
                    This game may be habit-forming or financially risky. Play
                    responsibly. Skillwinz is the biggest social gaming app in
                    India by number of games, languages and exciting formats on
                    the platform. Skillwinz is only available for people who are
                    above 18 years of age. Skillwinz is available only in those
                    Indian states where skill gaming is allowed by
                    regulations.Fantasy Score 11 Pvt. Ltd. is the sole owner of
                    and reserves the right to “Skillwinz” trademark, logos,
                    assets, content, information, etc. used in the website
                    except the third party content. Fantasy Score 11 Pvt. Ltd.
                    does not acknowledge the accuracy or reliability of third
                    party content.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
