import React from 'react'

const ContactUs = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> CONTACT US   </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"> CONTACT US   </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="common_content_box">

            <section id="contact" class="contact">
    <div class="container">
        <div class="section-title aos-init aos-animate section-heading w-100 pb-55" data-aos="fade-up">
            <h2  className='text_gradient' > Contact Us  </h2> 
        </div>
        <div class="row align-items-start">
            <div class="col-lg-12 aos-init aos-animate" data-aos="fade-right" data-aos-delay="100">
                <div class="info row align-items-start">
                    <div class="email  col-md-4 mt-md-0"><i class="bi bi-envelope"></i>
                        <h4>Email:</h4>
                        <p> support@skillwinz.com </p>
                    </div>
                    <div class="phone  col-md-4  mt-md-0"><i class="bi bi-phone"></i>
                        <h4>Call:</h4>
                        <p> +91 7878 140 068 </p>
                    </div>
                    <div class="address col-md-4"><i class="bi bi-geo-alt"></i>
                        <h4> Location: </h4>
                        <p> P.no 30, Hanman vatika Extension, Jaipur, Rajasthan. 302012 </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
            </section>
            {/* <!-- Copyright Area Start --> */}
            <div className="copyright-area  ptb-70 pt-0 pb-4">
                <div className="container">
                    {/* <!-- Contact address left --> */}
                    <div className="conct-border row align-items-center">
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" /> </a>
                        </div>
                        <div className="col-md-7">
                            <div className="single-address">
                                <a href="/fairplay"  >Skillwinz Fairplay</a>
                                <a href="/termsconditions">Terms & Conditions</a>
                                <a href="/privacypolicy">Privacy Policy</a>
                                <a href="/legality"> Legality </a>
                                <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                <a href="/anti-money-laundering-policy">Anti-Money Laundering  </a>
                                <a href="/refund-policy"> Refund Policy  </a>
                                <a href="/contact"> Contact us    </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Contact address left --> */}
                    {/* <!-- Copyright right --> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-area text-center">
                                {/* <!-- Copyright social --> */}
                                {/* <!-- <div className="contact-social text-center ptb-50 pb-35">
                                    <ul>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-pinterest"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-vimeo"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-google-plus"></i></a>
                                        </li>
                                    </ul>
                                </div> --> */}
                                {/* <!-- Copyright social --> */}
                                <div className="copyright-text">
                                    <p>Copyright &copy;  <a href="#"> Skill Winz </a> All Rights Reserved. by Skillwinz is a part of  Fantasy Score 11 Pvt. Ltd.</p>
                                
                                    <p>
                                        <b>DISCLAIMER</b><br/>
                                        This game may be habit-forming or financially risky. Play responsibly. Skillwinz is the biggest social gaming app in India by number of games, languages and exciting formats on the platform. Skillwinz is only available for people who are above 18 years of age. Skillwinz is available only in those Indian states where skill gaming is allowed by regulations.Fantasy Score 11 Pvt. Ltd. is the sole owner of and reserves the right to “Skillwinz” trademark, logos, assets, content, information, etc. used in the website except the third party content. Fantasy Score 11 Pvt. Ltd. does not acknowledge the accuracy or reliability of third party content.
                                    </p>
                                </div>
                                {/* <!-- Copyright text --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Copyright right --> */}
                </div>
            </div >
            {/* <!-- Copyright Area End --> */}
        </>
    )
}

export default ContactUs
